import React from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Highlight, { defaultProps } from "prism-react-renderer";

const Wrapper = styled.div`
    width: 100vw;
    height: 100vh;
`;

const Markup = styled.div`
    position: relative;
    //border-left: 13px solid #ff5001;
    height: 100%;

    > pre {
        width: 100%;
        height: 100%;
        overflow: auto;
        margin: 0;
        font-size: 12px;
        font-family: monospace;
    }
`;

const Button = styled.button`
    position: absolute;
    top: 1em;
    right: 1em;
    width: 100px;
`;

const CopyButton = ({ content }) => {
    return (
        <Button
            onClick={() => {
                navigator.clipboard.writeText(content).then(
                    function () {
                        console.log("Async: Copying to clipboard was successful!");
                    },
                    function (err) {
                        console.error("Async: Could not copy text: ", err);
                    }
                );
            }}
        >
            Copy
        </Button>
    );
};

export default function BlogPost({ data }) {
    return (
        <Wrapper>
            <Markup>
                {/* <CopyButton content={data.mjml.html} /> */}
                <Highlight {...defaultProps} code={data.mjml.html} language="html">
                    {({ className, style, tokens, getLineProps, getTokenProps }) => (
                        <pre className={className} style={style}>
                            {tokens.map((line, i) => (
                                <div {...getLineProps({ line, key: i })}>
                                    {line.map((token, key) => (
                                        <span {...getTokenProps({ token, key })} />
                                    ))}
                                </div>
                            ))}
                        </pre>
                    )}
                </Highlight>
            </Markup>
        </Wrapper>
    );
}

export const query = graphql`
    query($slug: String!) {
        mjml(fields: { slug: { eq: $slug } }) {
            html
        }
    }
`;
